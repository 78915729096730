import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { GavelIcon, LocationOnIcon } from '@img/icons';
import { navigator } from '@types';

const configNav = () => {
	const root = `/configuration`;
	return [
		{
			label: 'Dashboard',
			icon: (color:string) => <DashboardIcon style={{ color: color }} />,
			link: `${root}/dashboard`,
			enabled: true,
			activeURL: 'dashboard'
		},
		{
			label: 'Admin Rate Cards',
			icon: (color:string) => <PriceChangeOutlinedIcon style={{ color: color }} />,
			link: '/admin/configuration/ratecards',
			activeURL: 'ratecards',
			domain: 'eos'
		},
		{
			label: 'Regions',
			icon: (color:string) => <MapOutlinedIcon style={{ color: color }} />,
			link: `/admin/configuration/region`,
			activeURL: 'region',
			domain: 'eos'
		},
		{
			label: 'Locations',
			icon: (color: string) => <LocationOnIcon color={color}/>,
			link: `${root}/locations`,
			enabled: true,
			activeURL: 'locations'
		},
		{
			label: 'Truck Types',
			icon: (color:string) => <LocalShippingOutlinedIcon style={{ color: color }} />,
			link: `/admin/configuration/trucktypes`,
			activeURL: 'truck_types',
			domain: 'eos'
		},
		{
			label: 'Fuel Levy',
			icon: (color:string) => <LocalGasStationOutlinedIcon style={{ color: color }} />,
			link: `/admin/configuration/fuelLevy`,
			activeURL: 'fuel_levy',
			domain: 'eos'
		},
		{
			label: 'Marketplace',
			icon: (color:string) => <GavelIcon color={color} />,
			link: `/admin/configuration/marketplace`,
			activeURL: 'marketplace',
			domain: 'eos'
		},
		{
			label: 'Company',
			icon: (color:string) => <StoreOutlinedIcon style={{ color: color }} />,
			link: `/admin/configuration/company`,
			activeURL: 'company',
			domain: 'eos'
		}
	] as navigator[];
}

export default configNav;
