import {
	useRef,
	useEffect,
	useState,
	useLayoutEffect,
	PropsWithChildren
} from 'react';
import { createPortal } from 'react-dom';
import styles from './Tooltip.module.css';
import { TOOLTIP_POSITION, TObject } from '@types';

type TooltipProps = {
    position: TOOLTIP_POSITION,
    text: JSX.Element | string;
    wrapperStyles?: TObject;
    visible?: boolean;
	opacity?: number;
}

export const Tooltip = (props:PropsWithChildren<TooltipProps>) => {
	const {
		position,
		text,
		children,
		wrapperStyles = {},
		visible = true,
		opacity = 0.85
	} = props;
	const tooltipRef = useRef<HTMLDivElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [realPos, setRealPos] = useState(position);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [hovered, setHovered] = useState(false);
	const [hover, setHover] = useState(false);
	const getPosition = () => {
		switch (realPos) {
		case TOOLTIP_POSITION.TOP:
			return styles.top;
		case TOOLTIP_POSITION.LEFT:
			return styles.left;
		case TOOLTIP_POSITION.BOTTOM:
			return styles.bottom;
		default:
			return styles.right;
		}
	}
	const setRealStyles = () => {
		const styles:TObject = {};
		if (wrapperRef.current !== null) {
			const { x, y } = wrapperRef.current.getBoundingClientRect();
			const wrapperWidth = wrapperRef.current.offsetWidth;
			const wrapperHeight = wrapperRef.current.offsetHeight;
			let newX, newY;
			switch (realPos) {
			case TOOLTIP_POSITION.TOP:
			case TOOLTIP_POSITION.BOTTOM:
				newX = x - (width/2) + (wrapperWidth / 2);
				if (realPos === TOOLTIP_POSITION.TOP) {
					newY = y - height - 5;
				} else {
					newY = y + wrapperHeight + 5;
				}
				break;
			case TOOLTIP_POSITION.LEFT:
			case TOOLTIP_POSITION.RIGHT:
				newY = y - (height / 2) + (wrapperHeight / 2);
				if (realPos === TOOLTIP_POSITION.LEFT) {
					newX = x - width - 5;
				} else {
					newX = x + wrapperWidth + 5;
				}
				break;
			default:
			}
			styles.transform = `translate3d(${Math.round(newX ?? 0)}px, ${Math.round(newY ?? 0)}px, 0px)`;
			styles.backgroundColor = `rgba(1, 10, 38, ${opacity})`;
		}
		return styles;
	}
	const hovering = (b:boolean) => {
		setHover(b);
		if (!hovered) setHovered(true);
	}
	useLayoutEffect(() => {
		if (tooltipRef.current !== null) {
			const tt = tooltipRef?.current;
			const curWidth = tt?.offsetWidth;
			const curHeight = tt?.offsetHeight;
			setWidth(curWidth);
			setHeight(curHeight);
			const winWidth = window.innerWidth;
			const winHeight = window.innerHeight;
			const ttHeight = tt.offsetHeight;
			const ttWidth = tt.offsetWidth;
			const ttPosX = tt.getBoundingClientRect().x;
			const ttPosY = tt.getBoundingClientRect().y;
			const overflowRight = ttWidth + ttPosX > winWidth;
			const overflowLeft = ttPosX < ttWidth + 5;
			const overflowTop = ttPosY < ttHeight + 5;
			const overflowBottom = ttPosY + ttHeight > winHeight;
			switch (position) {
			case TOOLTIP_POSITION.LEFT:
				if (overflowLeft) {
					setRealPos(TOOLTIP_POSITION.RIGHT);
				}
				if (overflowTop) {
					setRealPos(TOOLTIP_POSITION.BOTTOM);
				}
				if (overflowBottom) {
					setRealPos(TOOLTIP_POSITION.TOP);
				}
				break;
			case TOOLTIP_POSITION.RIGHT:
				if (overflowRight) {
					setRealPos(TOOLTIP_POSITION.LEFT);
				}
				if (overflowTop) {
					setRealPos(TOOLTIP_POSITION.BOTTOM);
				}
				if (overflowBottom) {
					setRealPos(TOOLTIP_POSITION.TOP);
				}
				break;
			case TOOLTIP_POSITION.BOTTOM:
				if (overflowBottom) {
					setRealPos(TOOLTIP_POSITION.TOP);
				}
				if (overflowLeft) {
					setRealPos(TOOLTIP_POSITION.RIGHT);
				}
				if (overflowRight) {
					setRealPos(TOOLTIP_POSITION.LEFT);
				}
				break;
			default:
				if (overflowTop) {
					setRealPos(TOOLTIP_POSITION.BOTTOM);
				}
				if (overflowLeft) {
					setRealPos(TOOLTIP_POSITION.RIGHT);
				}
				if (overflowRight) {
					setRealPos(TOOLTIP_POSITION.LEFT);
				}
				break;
			}
		}
	}, [tooltipRef.current, text, hovered]); // eslint-disable-line
	useEffect(() => {
		setRealPos(position);
	}, [position]);
	return (
		<div ref={wrapperRef} style={wrapperStyles} className={styles.tooltip} onMouseEnter={() => hovering(true)} onMouseLeave={() => hovering(false)}>
			{visible && hover && createPortal(<div data-testid={'@Ofload-Tooltip'} className={`${styles.container} ${getPosition()} ${hover ? styles.hover : ''}`} style={setRealStyles()} ref={tooltipRef}>
				{text}
			</div>, document.body)}
			{children}
		</div>
	);
}
