import { useEffect } from 'react';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import { useGetEOSEnums } from '@api';
import { FeatureFlags, EOSENUMS, GoogleMapsLoaded } from '@state';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { Toaster } from 'react-hot-toast';
import { ToastUI, FlagsProvider } from '@components';
// import { ServerFlag } from '@types';
import { localFeatureFlags } from '../FeatureFlags.local';
import { useJsApiLoader, Libraries } from '@react-google-maps/api';
import { injectPendo, waitForPendoLoad } from '@utils';
import usePendoIdentity from '@hooks/usePendoIdentity.ts';

const mapsLibraries:Libraries = ['maps', 'marker'];

const Injector = () => {
	injectPendo('2299936e-93a6-4810-5cab-4965afb38241');
	usePendoIdentity();
	const configurePendo = () => {
		// Initial configuration of Pendo
		// Once logged in, the pendo identity will be updated
		(window as any).pendo.initialize({
			visitor: { id: 'VISITOR-UNIQUE-ID', },
			account: { id: 'ACCOUNT-UNIQUE-ID', }
		});
	}
	waitForPendoLoad(() => {
		configurePendo();
	});

	/*
	 * const {
	 * 	enums,
	 * 	error,
	 * 	callGetEnums
	 * } = useGetEnums();
	 */
	const {
		eosEnums,
		eosEnumError,
		callGetEOSEnums
	} = useGetEOSEnums();
	// const setEnums = useSetRecoilState(ENUMS);
	const setEosEnums = useSetRecoilState(EOSENUMS);
	/*
	 * const {
	 * 	featureFlags,
	 * 	ffError,
	 * 	callGetFeatureFlags
	 * } = useGetFeatureFlags(
	 * 	'3b1e6d57-65c8-4d4e-b08b-c2b9a8bbcfb1',
	 * 	'7DBFCF2214842E5B23DAC8D8BFA1B'
	 * );
	 */
	const [flags] = useRecoilState(FeatureFlags);
	const setGoogleMapsLoaded = useSetRecoilState(GoogleMapsLoaded);
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		libraries: mapsLibraries,
		googleMapsApiKey: 'AIzaSyAvKNuaG56f-g7coQFm3C-Ow_KY7I3MtTU' // -> NEW KEY: AIzaSyBzx4BBkEuJLl-znuvOZfZWdLsxz6v4o1o
	});

	useEffect(() => {
		/*
		 * === TEMP DISABLE THESE FETCHES - CURRENTLY UNUSED ===
		 * callGetEnums();
		 * callGetFeatureFlags();
		 */
		callGetEOSEnums();
	}, []);
	useEffect(() => {
		setGoogleMapsLoaded(isLoaded);
	}, [isLoaded]);
	useEffect(() => {
		// if (enums) setEnums(enums.data);
		if (eosEnums) setEosEnums(eosEnums.data);
	}, [eosEnums]);
	/*
	 * useEffect(() => {
	 * 	if (featureFlags) {
	 * 		const checkSettings = (s:string | null | undefined) => {
	 * 			if (!s) return false;
	 * 			if (s === '' || s === '{}') return false;
	 * 			return true;
	 * 		}
	 * 		const formattedFlags = featureFlags.map((flag:ServerFlag) => {
	 * 			const newFlag = {
	 * 				name: flag.name,
	 * 				on: checkSettings(flag.settings) ? JSON.parse(flag.settings ?? '') : flag.enabled,
	 * 				meta: flag
	 * 			}
	 * 			return newFlag
	 * 		});
	 * 		setFeatureFlags(formattedFlags);
	 * 	}
	 * }, [featureFlags]);
	 */
	useEffect(() => {
		/*
		 * if (error) console.log(error);
		 * if (ffError) console.log(ffError);
		 */
		if (eosEnumError) console.log(eosEnumError);
	}, [eosEnumError]);

	return (
		<>
			<FlagsProvider flags={[
				...localFeatureFlags,
				...flags
			]}>
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</FlagsProvider>
			<Toaster containerStyle={{ zIndex: 9999999999 }} />
			<ToastUI />
		</>
	);
}

export default Injector;
