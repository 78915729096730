import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styles from './TypeAhead.module.scss';
import { Options } from './Options';
import { TextInput } from '@components';
import { InputFieldProps } from '@components/FORM/InputField/InputField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TypeAheadOption, TGroupedOpts, TObject } from '@types';

export interface TypeAheadProps extends InputFieldProps {
	onSelected: (option:TypeAheadOption | string, props?: { group: string }) => void;
	showArrowIcon?: boolean;
	options: TypeAheadOption[] | string[] | TGroupedOpts | null;
	noOptionsText?: string;
	isLoadingOptions?: boolean;
	onClearInput?: (v: string) => void;
	onSelectOpen?: (b: boolean) => void;
}

export const TypeAhead = (props:TypeAheadProps) => {
	const {
		id,
		name,
		value,
		onChange,
		onSelected,
		showArrowIcon,
		options = [],
		noOptionsText,
		isLoadingOptions = false,
		onClearInput,
		onSelectOpen,
		placeholder,
		...otherProps
	} = props;
	const [position, setThePosition] = useState({});
	const [isAboveFold, setIsAboveFold] = useState(true);
	const [selectOpen, setSelectOpen] = useState(false);
	const [showList, setShowList] = useState(false);
	const listContainerRef = useRef<HTMLUListElement>(null);
	const txtInputRef = useRef<HTMLInputElement>(null);
	const selectid = useRef<string | null>(null);

	const setSelectid = () => {
		const now = Math.round(new Date(new Date()).getTime());
		selectid.current = `${id ? `${id}-` : ''}${Math.random()}-${now}`;
	}
	const selected = (o:TypeAheadOption | string, p?:{ group: string }) => {
		onSelected(o, p);
		setSelectOpen(false);
		setShowList(false);
		if (onSelectOpen) onSelectOpen(false);
	}
	const showIcon = () => {
		if (showArrowIcon) {
			return selectOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon
		}
		return undefined;
	}
	const focused = () => {
		setSelectOpen(true);
		if (onSelectOpen) onSelectOpen(true);
	}
	const blured = () => {
		// setSelectOpen(false);
	}
	const setPosition = () => {
		if (txtInputRef.current && listContainerRef.current) {
			const { offsetHeight, offsetWidth } = txtInputRef.current;
			const { left, top } = txtInputRef.current.getBoundingClientRect();
			const { offsetHeight: oHeight } = listContainerRef.current;
			const styles:TObject = {};
			styles.position = 'absolute';
			styles.left = 0;
			styles.top = 0;
			styles.width = `${offsetWidth + 22}px`;
			styles.minWidth = 'max-content';
			styles.willChange = 'transform';
			styles.maxHeight = `${window.innerHeight - top - 33}px`;
			if (isAboveFold) {
				styles.transform = `translate(${left - 11}px, ${offsetHeight + 13 + top}px)`;
			} else {
				styles.transform = `translate(${left - 11}px, ${top - 13 - oHeight}px)`;
			}
			return styles;
		}
		return {}
	}

	useEffect(() => {
		if (selectOpen && listContainerRef && listContainerRef.current) {
			setIsAboveFold(window.innerHeight - listContainerRef.current.getBoundingClientRect().y - listContainerRef.current.offsetHeight > 0);
			const newPos = setPosition();
			setThePosition(newPos);
			setTimeout(() => {
				setShowList(true)
			}, 100);
		}
	}, [selectOpen, options]);

	useEffect(() => {
		setSelectid();
	}, []);

	return (
		<>
			<TextInput
				{...otherProps}
				name={name}
				value={value}
				onChange={onChange}
				Icon={otherProps.Icon ?? showIcon()}
				iconSize={24}
				placeholder={placeholder || 'Start typing…'}
				inputRef={txtInputRef}
				onFocus={focused}
				onBlur={blured}
				id={selectid.current ?? props.id}
				onClearInput={onClearInput}
			/>
			{selectOpen && createPortal(<div className={styles.listContainer} onClick={() => setSelectOpen(false)}>
				<ul ref={listContainerRef} className={`${styles.list} ${showList ? styles.showList : ''}`} id={'typeahead-list'} role={'listbox'} style={position}>
					<Options
						options={options}
						selected={(o:TypeAheadOption | string, p?:{ group: string }) => selected(o, p)}
						noOptionsText={noOptionsText}
						isLoadingOptions={isLoadingOptions}
						selectid={selectid.current}
					/>
				</ul>
			</div>, document.body)}
		</>
	);
}
