import { useSearchParams, useNavigate } from 'react-router-dom';
import {
	ShipperSearch,
	CarrierSearch,
	ImpersonatedShipper,
	ImpersonatedCarrier,
} from '@types';
import { ActiveShipper, ActiveCarrier, JWTUserType } from '@state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';

const IMPERSONATION_LOCAL_STORAGE_KEY = {
	carrier: 'a1f65eb1-2717-4edf-97fd-5a07ddb27ced2',
	shipper: 'ac63ba8e-2d80-4145-8b98-73c5baa7dbbb2',
};

type ActiveShipperTokenResp = {
	id: number;
	token: number;
	business_name: string;
}
const fetchShipperById = async (id: number): Promise<ActiveShipperTokenResp> => {
	try {
		const response = await axios.post(`/admin/shipper/get-active-shipper-token`, { shipperId: id });
		return response.data.data as ActiveShipperTokenResp;
	} catch (error) {
		console.error(`Failed to fetch shipper with ID ${id}:`, error);
		throw error;
	}
};

export const useImpersonation = () => {
	const [search, setSearch] = useSearchParams();
	const navigate = useNavigate();
	const sid = search.get('sid');
	const cid = search.get('cid');
	const storedSid = sessionStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper);
	const storedCid = sessionStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier);
	const setActiveShipper = useSetRecoilState(ActiveShipper);
	const setActiveCarrier = useSetRecoilState(ActiveCarrier);
	const jwtUserType = useRecoilValue(JWTUserType);

	const impersonate = async (
		userType: 'carrier' | 'shipper' | null = null,
		impersonation: ShipperSearch | CarrierSearch | null = null
	) => {
		if (jwtUserType === 'shipper' || jwtUserType === 'carrier') {
			return;
		}
		if (!userType) {
			if (sid !== null) {
				if (storedSid !== null) {
					const activeShipper: ImpersonatedShipper = JSON.parse(storedSid);
					if (activeShipper.id !== parseInt(sid, 10)) {
						try {
							const fetchedShipper = await fetchShipperById(parseInt(sid, 10));
							const shipper: ImpersonatedShipper = {
								id: fetchedShipper.id,
								name: fetchedShipper.business_name,
								label: fetchedShipper.business_name,
								token: fetchedShipper.token.toString(),
								type: 'shipper',
							};
							sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper,
								JSON.stringify(shipper));
							setActiveShipper(shipper);
						} catch (error) {
							console.error('Failed to fetch shipper:', error);
							throw error;
						}
					} else {
						setActiveShipper(activeShipper);
					}
				} else {
					try {
						const fetchedShipper = await fetchShipperById(parseInt(sid, 10));
						const shipper: ImpersonatedShipper = {
							id: fetchedShipper.id,
							name: fetchedShipper.business_name,
							label: fetchedShipper.business_name,
							token: fetchedShipper.token.toString(),
							type: 'shipper',
						};
						sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper,
							JSON.stringify(shipper));
						setActiveShipper(shipper);
					} catch (error) {
						console.error('Failed to fetch shipper:', error);
					}
				}
			} else if (storedSid !== null) {
				const activeShipper: ImpersonatedShipper = JSON.parse(storedSid);
				setSearch((params) => {
					params.set('sid', `${activeShipper.id}`)
					return params
				});
				setActiveShipper(activeShipper);
			}

			if (cid !== null) {
				if (storedCid !== null) {
					const activeCarrier: ImpersonatedCarrier = JSON.parse(storedCid);
					if (activeCarrier.id !== parseInt(cid)) {
						// --> fetch Active Carrier via CID
					} else {
						setActiveCarrier(activeCarrier);
					}
				} else {
					// --> fetch Active Carrier via CID;
				}
			} else if (storedCid !== null) {
				const activeCarrier: ImpersonatedCarrier = JSON.parse(storedCid);
				setSearch((params) => {
					params.set('cid', `${activeCarrier.id}`)
					return params
				});
				setActiveCarrier(activeCarrier);
			}
		} else if (userType === 'carrier' && impersonation) {
			const carrier: ImpersonatedCarrier = {
				id: (impersonation as CarrierSearch).carrier_id!,
				name: impersonation.bus_name!,
				label: impersonation.bus_name!,
				token: (impersonation as CarrierSearch).carrier_id!.toString(),
				type: 'carrier',
			}
			setActiveCarrier(carrier);
			sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier, JSON.stringify(carrier));
			setSearch((params) => {
				params.set('cid', `${carrier.id}`)
				return params
			});
		} else if (userType === 'shipper' && impersonation as ShipperSearch) {
			const shipper: ImpersonatedShipper = {
				id: (impersonation as ShipperSearch).shipper_id,
				name: (impersonation as ShipperSearch).bus_name!,
				label: (impersonation as ShipperSearch).bus_name!,
				token: (impersonation as ShipperSearch).shipper_id!.toString(),
				type: 'shipper',
			}
			setActiveShipper(shipper);
			sessionStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper, JSON.stringify(shipper));
			setSearch((params) => {
				params.set('sid', `${shipper.id}`)
				return params
			});
		}
	}

	const refrain = (userType: 'carrier' | 'shipper' | null = null) => {
		if (userType) {
			if (userType === 'carrier') {
				sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier);
				setActiveCarrier(null);
				if (search.has('cid')) {
					search.delete('cid');
					setSearch(search);
				}
				navigate('/carrier/dashboard');
			} else {
				sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper);
				setActiveShipper(null);
				if (search.has('sid')) {
					search.delete('sid');
					setSearch(search);
				}
				navigate('/shipper/dashboard');
			}
		} else {
			sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.carrier);
			sessionStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY.shipper);
			setActiveShipper(null);
			setActiveCarrier(null);
		}
	}

	return {
		impersonate,
		refrain
	}
}
